/* --------------------------
Gナビ
-------------------------- */
.p-gnav {
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-base;
    padding: 15px 0;
    z-index: 10000;
  }

  @include view-at(tab) {
    background: rgba(148, 188, 208, 0.9);
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  &-list {
    max-width: $contents-width;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include view-at(tab) {
      flex-direction: column;
    }

    &-item {
      flex-grow: 1;
      padding: 0 10px;
      border-right: 1px solid $color-black;
      position: relative;

      &:hover {
        .p-gnav-drop-list {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s;
        }
      }

      &:first-child {
        padding-left: 0;

        @include view-at(tab) {
          padding: 25px;
        }
      }

      @include view-at(tab) {
        width: 100%;
        padding: 25px;
        border-right: none;
        text-align: center;
      }

      a {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-family: "Avenir", "Arial", sans-serif;
        font-size: 20px;
        letter-spacing: 0.06em;
        color: $color-blue;
        text-decoration: none;
        border-bottom: 2px solid transparent;

        @include view-at(tab) {
          color: $color-white;
        }

        &:hover {
          border-bottom: 2px solid $color-blue;
        }
      }

      &.current {
        a,
        div {
          border-bottom: 2px solid $color-blue;

          @include view-at(tab) {
            border-bottom: 2px solid $color-white;
          }
        }
      }
    }
  }

  &-button {
    flex-shrink: 0;
    border-right: none;
    padding-left: 50px;
    padding-right: 0;

    @include view-at(tab) {
      padding: 25px;
    }

    a {
      text-decoration: none;
      display: block;
      width: 100%;
      font-family: "Avenir", "Arial", sans-serif;
      font-size: 22px;
      letter-spacing: 0.06em;
      color: $color-blue;
      text-align: center;
      padding: 15px;
      border: 2px solid $color-blue;

      @include view-at(tab) {
        color: $color-white;
        border-color: $color-white;
      }
    }
  }

  &-drop {
    text-align: center;
    font-family: "Avenir", "Arial", sans-serif;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: $color-blue;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    position: relative;

    @include view-at(tab) {
      color: $color-white;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid $color-white;
        border-left: 2px solid $color-white;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% - 5px);
        right: 0;
      }

      &.active {
        margin-bottom: 10px;
      }
    }

    &:hover {
      border-bottom: 2px solid $color-blue;
      opacity: 1;
    }

    &-list {
      position: absolute;
      top: calc(100% + 15px);
      left: 0;
      width: 100%;
      background: $color-blue;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      padding: 0 20px;

      @include view-at(tab) {
        position: static;
        visibility: visible;
        background-color: transparent;
        transition: none;
        opacity: 1;
        display: none;

        &.visible {
          display: block;
        }
      }

      &-item {
        padding: 10px 5px;

        @include view-at(tab) {
          padding: 10px;
        }

        a {
          color: $color-white;
          border-bottom: 2px solid transparent;

          &:hover {
            border-bottom: 2px solid $color-white;
          }
        }
      }
    }
  }

  &-wrapper {
    @include view-at(tab) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }
  }
}
