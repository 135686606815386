/* --------------------------
背景色
-------------------------- */

.u-bg-light {
  background-color: $color-gray-snow;
}

.u-bg-image {
  background-image: url(../images/common/bg.png);
  background-position: top left;
  background-repeat: no-repeat;
}

.u-bg-blue {
  background-color: $color-blue;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-base;
    width: 10%;
    height: 100%;

    @include view-at(sp) {
      width: 20px;
    }
  }
}
