.archive {
  &-list {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    column-count: 2;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;

    @include view-at(sp) {
      column-count: 1;
    }

    > li {
      // width: 50%;
      margin-bottom: 5px;
      line-height: 24px;

      @include view-at(sp) {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
