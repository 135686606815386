.p-sns {
  &-list {
    display: flex;
    align-items: center;
    margin: -10px;

    &.gnav-sp {
      justify-content: center;
    }

    &-item {
      padding: 10px;

      a {
        display: block;
      }
    }
  }
}
