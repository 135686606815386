/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  margin-top: 20px;
  @include view-at(sp) {
    
  }

  &-list {
    list-style: none;
    margin: 0;
    display: flex;
    

    li {
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      letter-spacing: 0.06em;
      
      &::after {
        content: '>';
        padding: 0 1em;
      }
      
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
