/* --------------------------
フッター
-------------------------- */

.p-footer {
  background: $color-blue;
  padding: 60px 0;

  @include view-at(sp) {
    padding: 30px 0;
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include view-at(sp) {
      flex-direction: column;
    }
  }

  &-copyright {
    font-family: "Libre Baskerville", serif;
    color: $color-white;
    line-height: 1;
    font-size: 17px;
    letter-spacing: 0.06em;

    @include view-at(tab) {
      margin-top: 30px;
      font-size: 12px;
      line-height: 2;
      text-align: center;
    }
  }
}
