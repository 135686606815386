.top {
  &-banner{
    border-left:1px solid $color-blue;
    padding-left: 30px;
    
    @include view-at(sp){
      border-left:none;
      padding-left: 0;
    }



    &-inner{
      background: #d1e2eb;
      margin-top: 30px;
      padding: 30px 0;
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      color:$color-blue;
      line-height: 1.75;
      letter-spacing: 0.06em;
      margin-bottom: 10px;
      

      @include view-at(sp) {
        text-align: center;
        font-size: 25px;
        
      }
    }

    &-list{
      margin: -5px;

      

      &-item{
        padding:5px;
      }
    }

  }
  &-title {
    @include view-at(sp) {
      position: relative;
      width: 100%;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url(../images/common/logo-symbol.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 110px;
        height: 100%;
      }
    }
  }
  &-panel {
    max-width: 620px;
    width: 100%;
    padding-right: 30px;

    @include view-at(tab) {
      max-width: 500px;
    }

    &-list {
      margin: -25px 0;

      &-item {
        padding: 25px 0;
      }
    }
    &-contents {
      display: flex;
      justify-content: space-between;

      @include view-at(sp) {
        flex-direction: column;
      }
    }

    &-english {
      margin-bottom: 0;
      width: 60%;
      color: $color-white;
      padding-right: 40px;

      @include view-at(sp) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
      }
    }

    &-japan {
      width: 40%;

      @include view-at(sp) {
        width: 100%;
      }
    }
  }

  &-eyecatch {
    position: relative;
    margin-top: 75px;
    height: 100%;
    z-index: 100;

    @include view-at(sp) {
      margin-top: 0;
      padding-top: 30px;
    }

    &-image {
      position: absolute;
      top: -75px;
      right: 0;
      width: 30%;
      z-index: 101;

      @include view-at(sp) {
        display: flex;
        width: 90%;
        position: relative;
        top: 0;
        right: 0;
        margin-left: auto;
      }

      &-item {
        margin-bottom: 65px;

        @include view-at(sp) {
          margin-bottom: 0;
        }
      }
    }
  }

  &-section {
    background: url(../images/top/section-bg.jpg);
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;

    @include view-at(sp) {
      background-size: auto 50%;
      background-position: top center;
      background-color: rgba(98, 157, 186, 0.6);
    }

    &-container {
      width: 100%;
      max-width: 1170px;
      padding: 0 25px;
      margin: 0 auto;
    }
  }
}
