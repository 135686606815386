#newsletter {
  .iIDDUy {
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    max-width: 35.25rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2.5rem 2.75rem;
    position: relative;
    background-position: initial initial;
    background-repeat: initial initial;
  }

  .ebeRtN {
    align-items: flex-start;
    display: flex;
  }

  .iHsWXX {
    font-size: 0.875rem;
    padding-left: 0.5rem;
    word-wrap: break-word;
    word-break: break-word;
    font-family: Helvetica, Arial, sans-serif;
    color: rgb(67, 77, 93);
  }

  .jMsntP {
    color: rgb(221, 54, 42);
    font-weight: 400;
    font-size: 0.75rem;
    margin-left: 0.125rem;
  }

  .eKOoKL {
    flex-shrink: 0;
    height: 1rem;
    position: relative;
    top: 0.08rem;
    width: 1rem;
  }

  .dEVaGV {
    font-family: Helvetica, Arial, sans-serif;
    color: rgb(93, 93, 101);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .cWlEVA {
    color: rgb(221, 54, 42);
    font-style: normal;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }

  .iFTUZ {
    color: rgb(221, 54, 42);
  }

  .iMsgpL {
    -webkit-appearance: none;
    background-color: rgb(255, 255, 255);
    border: 0px;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    box-sizing: border-box;
    box-shadow: rgba(142, 154, 173, 0.1) 0px 2px 0px 0px inset,
      rgb(210, 215, 223) 0px 0px 0px 1px inset,
      rgb(255, 255, 255) 0px 1px 0px 0px;
    color: rgb(67, 77, 93);
    font-size: 0.875rem;
    line-height: 1.5;
    min-height: 2.8125rem;
    outline: 0px;
    padding: 0.75rem 1rem;
    transition: box-shadow 0.2s ease;
    width: 100%;
  }

  .iMsgpL:focus {
    box-shadow: transparent 0px 0px 0px 0px inset,
      rgb(80, 156, 246) 0px 0px 0px 1px inset,
      rgba(80, 156, 246, 0.25) 0px 0px 0px 2px;
  }

  .glvDdt {
    -webkit-appearance: none;
    background-color: rgb(255, 255, 255);
    border: 0px;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    box-sizing: border-box;
    box-shadow: rgb(221, 54, 42) 0px 0px 0px 1px,
      rgb(232, 82, 68) 0px 0px 4px 0px, rgb(232, 82, 68) 0px 0px 2px -2px;
    color: rgb(67, 77, 93);
    font-size: 0.875rem;
    line-height: 1.5;
    min-height: 2.8125rem;
    outline: 0px;
    padding: 0.75rem 1rem;
    transition: box-shadow 0.2s ease;
    width: 100%;
  }

  .glvDdt:focus {
    box-shadow: rgb(221, 54, 42) 0px 0px 0px 1px,
      rgb(232, 82, 68) 0px 0px 4px 0px, rgb(232, 82, 68) 0px 0px 2px -2px;
  }

  .bMslyb {
    margin-bottom: 1.5rem;
  }

  .liOVdz {
    margin-bottom: 1rem;
  }

  .czBTns {
    text-decoration-line: none;
    text-decoration: none;
    color: rgb(0, 0, 0);
  }

  .dLkilY {
    margin-left: auto;
    margin-right: auto;
    max-width: 29.125rem;
    padding-bottom: 3.125rem;
  }

  .chDgPp {
    font-size: 42px;
    text-align: center;
    letter-spacing: -1px;
    line-height: 1.17;
    -webkit-font-smoothing: antialiased;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: rgb(67, 77, 93);
  }

  .wSZJN {
    font-size: 3rem;
    text-align: center;
    letter-spacing: -1px;
    line-height: 1.17;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    font-family: Lato, sans-serif;
    color: rgb(0, 0, 0);
  }

  .cdSBzU {
    font-size: 48px;
    text-align: center;
    letter-spacing: -1px;
    line-height: 1.17;
    -webkit-font-smoothing: antialiased;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: rgb(67, 77, 93);
  }

  .bguLUo {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(210, 215, 223);
    margin-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .urQia {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(210, 215, 223);
    margin-bottom: 0.75rem;
    padding-top: 0.75rem;
  }

  .gnexaL {
    margin-bottom: 0.25rem;
  }

  .jhkOqi {
    background-color: rgb(67, 77, 93);
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    outline: 0px;
    padding: 0.75rem 1.5rem;
    text-decoration-line: none;
    text-decoration: none;
    transition: background-color 0.1s ease-in, box-shadow 0.1s ease-in;
  }

  .jhkOqi:hover {
    cursor: pointer;
  }

  .jHkwuK {
    background-color: rgb(123, 177, 61);
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    outline: 0px;
    padding: 0.75rem 1.5rem;
    text-decoration-line: none;
    text-decoration: none;
    transition: background-color 0.1s ease-in, box-shadow 0.1s ease-in;
  }

  .jHkwuK:hover {
    cursor: pointer;
  }

  .fBOZaM {
    white-space: pre-wrap;
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    color: rgb(67, 77, 93);
  }

  a,
  abbr,
  address,
  article,
  aside,
  b,
  blockquote,
  body,
  caption,
  cite,
  code,
  dd,
  details,
  dialog,
  div,
  dl,
  dt,
  em,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  html,
  i,
  iframe,
  img,
  label,
  legend,
  li,
  main,
  menu,
  nav,
  object,
  ol,
  p,
  pre,
  section,
  small,
  span,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  u,
  ul {
    border: 0px;
    margin: 0px;
    padding: 0px;
  }

  *,
  ::after,
  ::before {
    box-sizing: inherit;
  }

  html {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  html {
    color: rgb(67, 77, 93);
    font-weight: 400;
    line-height: 1.5;
  }

  a {
    color: rgb(25, 169, 229);
    text-decoration-line: underline;
    text-decoration: underline;
  }

  button,
  label,
  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
}
