/* --------------------------
見出し
-------------------------- */

.c-h1 {
  font-family: adobe-caslon-pro, serif;
  font-size: 52px;
  color: $color-blue;
  letter-spacing: 0.06em;
  text-align: center;
  margin: 150px 0 50px;

  span {
    display: inline-block;
    padding-bottom: 15px;
    border-bottom-style: solid;
    border-bottom-width: 20px;
    border-bottom-color: $color-blue;
  }

  @include view-at(sp) {
    font-size: 32px;
    margin: 65px 0 60px;
    line-height: 1.2;

    span {
      padding-bottom: 10px;
      border-bottom-width: 12px;
    }
  }
}

.c-h2 {
  font-family: adobe-caslon-pro, serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 1.3;
  letter-spacing: 0.06em;
  margin-bottom: 70px;

  @include view-at(sp) {
    font-size: 32px;
    margin-bottom: 35px;
  }
}

.c-h3 {
  font-family: adobe-caslon-pro, serif;
  font-size: 34px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: 24px;
  }
}

.c-h4 {
  font-family: adobe-caslon-pro, serif;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  color: $color-blue;

  @include view-at(sp) {
    font-size: 24px;
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
