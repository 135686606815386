/* --------------------------
枠線
-------------------------- */

.u-border {
  &-bottom {
    border-bottom: 1px solid $color-black;
  }

  &-top {
    border-top: 1px solid $color-black;
  }

  &-right {
    border-right: 1px solid $color-black;
  }

  &-left {
    border-left: 1px solid $color-black;
  }

  &-white {
    border-color: $color-white !important;
  }
}
