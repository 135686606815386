/* --------------------------
テキスト
-------------------------- */

.p-text {
  &-english {
    // font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    margin-bottom: 40px;

    @include view-at(sp) {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  &-japan {
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.06em;
    padding-left: 30px;
    border-left: 1px solid $color-black;
    margin-bottom: 0;

    @include view-at(sp) {
      font-size: 10px;
      line-height: 1.85;
      padding-left: 20px;
    }
  }
}
