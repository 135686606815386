.analysis {
  &-list {
    margin: -10px;
    @include view-at(sp) {
      margin: -15px;
    }

    &-item {
      padding: 10px;

      @include view-at(sp) {
        padding: 15px;
      }

      a {
        display: flex;
        text-decoration: none;

        @include view-at(sp) {
          flex-direction: column;
        }
      }
    }
  }

  &-thumbnail {
    width: 280px;
    flex-shrink: 0;
    margin-right: 40px;

    @include view-at(sp) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }
  }

  &-h {
    // font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 1.2;
    color: $color-blue;
    font-weight: $bold;
    letter-spacing: 0.03em;
    margin-bottom: 20px;

    &--single {
      font-size: 25px;
    }
  }

  &-text {
    // font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.03em;

    @include view-at(sp) {
      font-size: 14px;
    }
  }

  &-contents {
    width: 100%;

    @include view-at(sp) {
      width: 100%;
    }

    p {
      color: $color-black;
    }
  }

  &-link {
    font-size: 14px;
    color: $color-blue;
    letter-spacing: 0.06em;
    text-align: right;
    text-decoration: underline;
  }

  &-section {
    padding-top: 60px;
    border-top: 1px solid $color-gray;
  }

  &-single {
    &-title {
      font-family: "Libre Baskerville", serif;
      font-size: 36px;
      color: $color-blue;
      margin-bottom: 30px;
      line-height: 1.6;

      @include view-at(sp) {
        font-size: 20px;
        margin-bottom: 15px;
      }

      &-details {
        font-family: "Libre Baskerville", serif;
        font-size: 20px;
        letter-spacing: 0.04em;
        margin-bottom: 20px;

        @include view-at(sp) {
          font-size: 15px;
          margin-bottom: 10px;
        }

        .category {
          color: $color-blue;
        }
      }
    }

    &-thumbnail {
      margin-bottom: 50px;
    }

    &-h {
      font-family: "Roboto", sans-serif;
      font-size: 25px;
      color: $color-blue;
      letter-spacing: 0.06em;
      margin-bottom: 20px;
    }
  }
}
