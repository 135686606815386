/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  font-size: 22px;
  letter-spacing: 0.06em;
  padding: 20px 60px 20px 20px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-black;
  border: 2px solid $color-black;
  max-width: 285px;
  width: 100%;
  position: relative;

  @include view-at(sp) {
    max-width: 100%;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% + 3px);
    right: 20px;
    width: 20px;
    height: 2px;
    background: $color-black;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 1px);
    right: 20px;
    width: 10px;
    height: 10px;
    border-top: 2px solid $color-black;
    transform: rotate(45deg);
  }

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;

    &::before {
      background: $color-white;
    }

    &::after {
      border-color: $color-white;
    }
  }
}
