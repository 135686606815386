.wp-pagenavi {
  text-align: center;

  .pages {
    display: none;
  }
  a {
    &:hover {
      border: 1px solid $color-blue;
    }
  }
  span {
    &.current {
      border: 1px solid $color-blue;
    }
  }

  span,
  a {
    /*数字部分の共通CSS　大きさなど*/
    display: inline-block;
    margin: 0 5px !important;
    width: 48px;
    padding: 15px 0 !important;
    color: $color-blue;
    text-decoration: none;
    border: 1px solid $color-blue;
    border-radius: 50%;

    &.current,
    &:hover {
      /*現在のページ*/
      color: $color-white;
      background: $color-blue;
    }

    &.nextpostslink,
    &.previouspostslink {
      display: none;

      // border: 1px solid $color-blue;

      // &:hover {
      //   color: $color-white;
      //   background:$color-blue;
      // }
    }
  }
}
