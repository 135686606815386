/* --------------------------
フォーム
-------------------------- */

.c-form {
  &-text {
    border: 1px solid $color-gray-light;
    width: 100%;
    padding: 6px 5px;
    font-size: nth($font-size, 3);
    line-height: nth($line-height, 3);
    

    
  }

  &-textarea {
    border: 1px solid $color-gray-light;
    width: 100%;
    padding: 8px 5px;
    height: 100px;
    border-radius: 3px;
  }

  &-checkbox {
    &-input {
      display: none;

      &:checked + .c-form-checkbox-span::after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 6px;
        width: 8px;
        height: 12px;
        transform: rotate(40deg);
        border-bottom: 3px solid $color-black;
        border-right: 3px solid $color-black;
      }
    }

    &-span {
      padding-left: 28px;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $color-gray-light;
        background-color: $color-white;
      }
    }
  }

  &-radio {
    &-input {
      display: none;

      &:checked + .c-form-radio-span::after {
        content: "";
        display: block;
        position: absolute;
        top: 8px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-black;
      }
    }

    &-span {
      padding-left: 28px;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $color-gray-light;
        background-color: $color-white;
      }
    }
  }
}
