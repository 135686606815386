.single-event-content .edit-post-visual-editor,
.single-deepdive-content .edit-post-visual-editor,
.entry-content {
  h2 {
    @extend .c-h2;
  }
  h3 {
    @extend .c-h3;
  }

  h4 {
    @extend .c-h4;
  }

  ul {
    @extend .c-list;
    @extend .c-list--point;
  }

  ol {
    @extend .c-list;
    @extend .c-list--order;
  }

  a {
    text-decoration: underline;
  }
}

strong {
  font-weight: $bold;
}

.wp-block-image figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
  text-align: center;
  font-size: 0.75rem;
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-table,
.wp-block-cover,
.wp-block-quote,
.wp-block-separator,
.wp-block-button {
  margin: nth($space, 3) 0;
}

.wp-block-table {
  @extend .c-table;
  margin: nth($space, 3) 0;
  border: 1px solid #ccc;
}

.wp-block-table tr {
  padding: 10px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
}

.wp-block-table td {
  padding: 10px;
  border-right: 1px solid #ccc;

  &:last-child {
    border-right: none;
  }
}
.wp-block-quote {
  padding: 20px;
  background-color: $color-blue;

  p {
    color: $color-white;
    margin-bottom: 0 !important;
  }
}

.wp-block-image {
  &::after {
    content: "";
    clear: both;
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }
}

.wp-block-table {
  &.aligncenter,
  &.alignleft,
  &.alignright {
    width: 100%;
  }
}

@include view-at(tab) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}

@include view-at(sp) {
  .wp-block-column + .wp-block-column {
    margin-top: nth($space, 2);
  }
}
