/* --------------------------
ラベル
-------------------------- */

.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: $color-gray-light;
  color: $color-white;
  font-size: nth($list: $font-size, $n: 6);
  letter-spacing: 1px;

  &--blue-dark {
    background-color: $color-black;
  }

  &--red {
    background-color: $color-black;
  }

  &--blue {
    background-color: $color-black;
  }

  &--green {
    background-color: $color-gray;
  }

  &--large {
    padding: 6px 12px;
    font-size: nth($list: $font-size, $n: 4);
  }

  &--small {
    padding: 5px 8px;
    font-size: nth($list: $font-size, $n: 6);
  }
}
