/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-blue;
  color: $color-white;
  border-color: $color-white;

  &-contents {
    padding: 50px 75px;

    @include view-at(tab) {
      padding: 40px 30px;
    }
  }

  &--arrow {
    position: relative;

    .c-panel-contents {
      padding-bottom: 120px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: block;
      width: 50px;
      height: 50px;
      border-bottom: 2px solid $color-white;
      border-right: 2px solid $color-white;
      transform: rotate(45deg);

      @include view-at(tab) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &-h {
    font-family: "Libre Baskerville", serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.3;
    letter-spacing: 0.06em;
    margin-bottom: 40px;

    @include view-at(tab) {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
}
