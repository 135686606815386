html {
  font-size: 16px;
  touch-action: manipulation;
}

body {
  font-family: "Avenir", "Helvetica Neue", "Helvetica", "Arial" sans-serif;
  // font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: $color-black;
  background: $color-base;
}

a {
  color: $color-blue;
  text-decoration: underline;

  &:hover {
    opacity: 0.6;

    img {
      opacity: 0.6;
    }
  }
}

p {
  margin-bottom: 1em;

  line-height: nth($line-height, 4);
  font-feature-settings: "palt";

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}
