.events {
  &-list {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: calc(100% / 3);
      padding: 10px;

      @include view-at(sp) {
        width: 100%;
      }

      a {
        display: block;

        text-decoration: none;
      }
    }
  }

  &-thumbnail {
    width: 100%;

    img {
      display: block;
    }
  }

  &-contents {
    padding: 20px 30px;
    background: $color-white;
  }

  &-title {
    // font-family: "Roboto", sans-serif;
    font-weight: $bold;
    font-size: 20px;
    color: $color-blue;
    letter-spacing: 0.06em;
    line-height: 1.4;
  }

  &-details {
    // font-family: "Roboto", sans-serif;
    font-weight: $bold;
    margin-bottom: 20px;

    &.events-single {
      .events-details-item {
        font-size: 18px;
      }
    }
    &-item {
      font-size: 14px;
      color: $color-blue;
      line-height: 1.8;
      letter-spacing: 0.1em;
    }
  }

  &-text {
    // font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: $color-black;
    line-height: 1.75;
    letter-spacing: 0.06em;
    margin-bottom: 20px;

    @include view-at(sp) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &-link {
    font-size: 14px;
    color: $color-blue;
    letter-spacing: 0.06em;
    text-align: right;
    text-decoration: underline;
  }

  &-section {
    padding-top: 60px;
    border-top: 1px solid $color-gray;
  }

  &-single {
    &-title {
      font-family: "Libre Baskerville", serif;
      font-size: 36px;
      color: $color-blue;

      @include view-at(sp) {
        font-size: 20px;
      }
    }

    &-thumbnail {
      margin: 50px 0;

      @include view-at(sp) {
        margin: 20px 0;
      }
    }

    &-panel {
      background: $color-blue;
      margin: 50px 0;

      @include view-at(sp) {
        margin: 50px -30px;
      }

      &-contents {
        padding: 20px;
      }

      &-h {
        // font-family: "Roboto", sans-serif;
        font-size: 30px;
        color: $color-white;
        text-align: center;
        letter-spacing: 0.1em;
      }
    }
  }
}
