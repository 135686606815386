/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 115px;

  @include view-at(tab) {
    padding-top: 20px;
  }

  &.topstyle {
    padding-top: 150px;

    @include view-at(tab) {
      padding-top: 20px;
    }
  }

  &-inner {
    max-width: 1060px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  &-wrapper {
    margin-bottom: 25px;

    @include view-at(tab) {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.topstyle {
      margin-bottom: 35px;

      @include view-at(tab) {
        margin-bottom: 0;
      }
    }
  }

  &-logo {
    text-align: left;
    font-size: 0;

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;

      @include view-at(pc) {
        img {
          width: auto;
          height: 72px;
        }
      }

      @include view-at(sp) {
        img {
          width: auto;
          height: 40px;
        }
      }
    }

    &.topstyle {
      a img {
        width: auto;
        height: 90px;

        @include view-at(tab) {
          width: auto;
          height: 72px;
        }

        @include view-at(sp) {
          width: auto;
          height: 40px;
        }
      }
    }
  }
}
